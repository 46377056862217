//
import React from "react"

//
import Layout from '../layout/index';
import Faq from 'routes/faq/index.js';

/*
 *
 */
export default (props) => (
	<Layout {...props}>
		<Faq />
	</Layout>
);