/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Hero from 'components/hero';
import FAQ from 'components/faq';
import H2 from 'components/h2';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';

/*
 *
 */

export default function Faq () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.faq.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.faq.meta_desc' })} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: 'page.faq.title' })}
					subtitle={intl.formatMessage({ id: 'page.faq.subtitle' })}
				/>
			</Section>
			<Section>
				<div itemScope itemType="https://schema.org/FAQPage">
					<H2>{intl.formatMessage({ id: 'page.faq.section.customer.title' })}</H2>
					<FAQ
						questions={[
							{
								question: intl.formatMessage({ id: 'page.faq.section.customer.1.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.customer.1.a'})
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.customer.2.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.customer.2.a'}),
								learnMoreLink : intl.formatMessage({ id: 'page.faq.section.customer.2.link'}),
								learnMoreText : intl.formatMessage({ id: 'page.faq.section.customer.2.linkText'})
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.customer.3.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.customer.3.a'}),
								learnMoreLink : intl.formatMessage({ id: 'page.faq.section.customer.3.link'}),
								learnMoreText : intl.formatMessage({ id: 'page.faq.section.customer.3.linkText'})
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.customer.4.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.customer.4.a'}),
								learnMoreLink : intl.formatMessage({ id: 'global.link.add_restaurant'}),
								learnMoreText : intl.formatMessage({ id: 'page.faq.section.customer.4.linkText'})
							}

						]}
					/>

					<H2>{intl.formatMessage({ id: 'page.faq.section.restaurant.title' })}</H2>
					<FAQ
						questions={[
							{
								question: intl.formatMessage({ id: 'page.faq.section.restaurant.1.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.restaurant.1.a'}),
								learnMoreLink : intl.formatMessage({ id: 'page.faq.section.restaurant.1.link'}),
								learnMoreText : intl.formatMessage({ id: 'page.faq.section.restaurant.1.linkText'})
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.restaurant.2.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.restaurant.2.a'}),
								learnMoreLink : intl.formatMessage({ id: 'page.faq.section.restaurant.2.link'}),
								learnMoreText : intl.formatMessage({ id: 'page.faq.section.restaurant.2.linkText'})
							}
						]}
					/>

					<H2>{intl.formatMessage({ id: 'page.faq.section.internal.title' })}</H2>
					<FAQ
						questions={[
							{
								question: intl.formatMessage({ id: 'page.faq.section.internal.1.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.internal.1.a'}),
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.internal.2.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.internal.2.a'}),
								learnMoreLink : intl.formatMessage({ id: 'page.faq.section.internal.2.link'}),
								learnMoreText : intl.formatMessage({ id: 'page.faq.section.internal.2.linkText'})
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.internal.3.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.internal.3.a'}),
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.internal.4.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.internal.4.a'}),
							},
							{
								question: intl.formatMessage({ id: 'page.faq.section.internal.5.q'}),
								answer: intl.formatMessage({ id: 'page.faq.section.internal.5.a'}),
							}
						]}
					/>
				</div>
			</Section>
		</React.Fragment>
	);
}