/*
 *
 */
import React from 'react';
import styled from 'styled-components';

const QuestionList = styled.ul`
	margin:0 -1rem;
	padding:0;
	list-style:none;
	justify-content: stretch;
	font-size:1.5rem;
	line-height:1.5em;
	display:flex;
	flex-wrap:wrap;

	&:last-child {
		margin-bottom: -2rem;
	}
`;

const Question = styled.li`
	width:100%;
	padding:0 1rem 1rem;
	box-sizing:border-box;

	@media (${props => props.theme.media.tablet}) {
		padding:0 1rem 2rem;
		width:50%;
	}
`;

const QuestionBox = styled.div`
	height:100%;
	padding:2.45rem 3rem;
	background:#fff;
	border:1px solid #eaeaea;
	border-radius:.35rem;
	text-align:left;
	box-sizing: border-box;

	display: flex;
    flex-direction: column;

	text-decoration:none;
	box-shadow:0 0 2rem 0 rgba(0,0,0,.05);
`;

const QuestionName = styled.p`
	color:#161a1e;
	font-weight:500;
	font-size:1.7rem;
	margin:0 0 0.5em;
`;

const QuestionAnswer = styled.p`
	border-bottom:1px solid transparent;
	transition:all .3s cubic-bezier(0.23,1,0.32,1);
	word-break: break-word;
	margin:0px;
`;

const LearnMore = styled.p`
	margin-bottom: 0px;
`;


const LearnMoreLink = styled.a`
	color: #1b83a4;
	text-decoration: none;
	transition: 0.3s;
	margin-top: 2rem;
	font-weight: 500;
	margin-top: auto;

	&:hover {
		border-bottom: 1px solid #1b83a4;
	}
`;


/*
 *
 */
export default function FaqList ({ questions }) {
	return (
		<QuestionList>
			{questions.map(q => (
				<Question>
					<QuestionBox itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
						<QuestionName itemProp="name">{q.question}</QuestionName>
						<QuestionAnswer itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
							<span itemProp="text">{q.answer}</span>
						</QuestionAnswer>
						{q.learnMoreLink ? <LearnMore><LearnMoreLink href={q.learnMoreLink} target="_blank noopener noreferrer">{q.learnMoreText}</LearnMoreLink></LearnMore> : ''}
					</QuestionBox>
				</Question>
			))}
		</QuestionList>
	);
}