/*
 *
 */
import React from 'react';
import styled from 'styled-components';

const Heading = styled.h2`
	margin:1em 0 .66em;

	&:first-child {
		margin-top:0;
	}

	font-size:1.9em;
	font-weight:500;
	letter-spacing:.015em;
	text-align:center;

	@media (${props => props.theme.media.tablet}) {
		font-size:2.3em;
		text-align:left;
	}
`;

/*
 *
 */
export default function H2 ({ children }) {

	return (
		<Heading>
			{children}
		</Heading>
	);
}
